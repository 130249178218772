import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'login',
  component: () => import('../views/login.vue'),
}, {
  path: '/export',
  name: 'export',
  component: () => import('../views/export.vue'),
}, {
  path: '/',
  name: 'indexStatistics',
  component: () => import('../views/indexStatistics.vue')
}, {
  path: '/indexCheck',
  name: 'indexCheck',
  component: () => import('../views/indexCheck.vue')
}, {
  path: '/indexFamilial',
  name: 'indexFamilial',
  component: () => import('../views/indexFamilial.vue')
}, {
  path: '/indexInspect',
  name: 'indexInspect',
  component: () => import('../views/indexInspect.vue')
}]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
});
export default router