import request from './api'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 门店信息
// 列表
export function shopList(region) {
  return request({
    url: '/screen/shop/list/all?region=' + region,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 地图家宴
export function sederList(region) {
  return request({
    url: '/screen/seder/list?region=' + region,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 检查详情

export function shopCheck(shopId) {
  return request({
    url: `/screen/shop/${shopId}`,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}

export function shopChecks(shopId) {
  return request({
    url: `/screen/shop/check/${shopId}`,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 综合检查

export function audit(region, startTime, endTime) {
  return request({
    url: `/screen/comprehensive/audit?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
export function statistics(region, startTime, endTime) {
  return request({
    url: `/screen/decision/shop/statistics?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 获取阳光厨房列表
export function macList(region, startTime, endTime, pageNum) {
  return request({
    url: `/screen/decision/shop/mac/list?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime + '&pageNum=' + pageNum + '&pageSize=4',
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 获取阳光厨房视频
export function macIdVideo(macId) {
  return request({
    url: `/mobile/video/path?macId=` + macId,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 门店员工列表
export function employeList(shopId) {
  return request({
    url: `/system/employe/list?shopId=` + shopId,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 农村家宴大屏
export function sederStatistics(region, endTime, startTime) {
  return request({
    url: `/screen/seder/statistics?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 心跳保活接口
export function keepLive(macId) {
  return request({
    url: `/mobile/keep/live`,
    headers: {
      isToken: true
    },
    method: 'POST',
    data: macId
  })
}
// 农村家宴备案列表
export function registrationList(region, startTime, endTime, isCheck, pageNum) {
  return request({
    url: `/screen/seder/registration/list?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime + '&isCheck=' + isCheck + '&pageNum=' + pageNum + '&pageSize=6',
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 厨师列表
export function cookList(startTime, endTime, pageNum) {
  return request({
    url: `/system/cook/list?endTime=` + endTime + '&startTime=' + startTime + '&type=' + 1 + '&pageNum=' + pageNum + '&pageSize=4',
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 检查大屏
export function superStatistics(region, startTime, endTime) {
  return request({
    url: `/screen/supervision/statistics?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 检查大屏
export function supervisionList(region, startTime, endTime, shopName, abarbeitung, pageNum) {
  return request({
    url: `/screen/supervision/list?endTime=` + endTime + '&region=' + region + '&startTime=' + startTime + '&shopName=' + shopName + '&abarbeitung=' + abarbeitung + '&pageNum=' + pageNum + '&pageSize=10',
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}

// 聚餐信息
export function systemSeder(id) {
  return request({
    url: '/system/registration/' + id,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}

// 根据厨师id查询厨师详情
export function systemCook(id) {
  return request({
    url: '/system/cook/' + id,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}

// 根据家宴id查询家宴详情
export function sederInfo(id) {
  return request({
    url: '/system/seder/' + id,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 根据家宴id查询家宴检查详情
export function registrationCheck(id) {
  return request({
    url: '/system/registration/check/' + id,
    headers: {
      isToken: true
    },
    method: 'GET'
  })
}
// 查询厨师评分列表
export function scoreList(data) {
  return request({
    url: '/system/score/list',
    headers: {
      isToken: true
    },
    method: 'POST',
    data: data
  })
}
// 查询厨师评分列表
export function scoreList2(data) {
  return request({
    url: '/screen/seder/cook/score/list',
    headers: {
      isToken: true
    },
    method: 'POST',
    data: data
  })
}

// 导出门店列表
export function exportShop() {
  return request({
    url: '/system/shop/export',
    headers: {
      isToken: true
    },
    method: 'POST'
  })
}
export function exportRegistration() {
  return request({
    url: '/system/registration/export',
    headers: {
      isToken: true
    },
    method: 'POST'
  })
}